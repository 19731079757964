import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout lang="ar">
    <SEO title="Home" />

    <StaticImage
      style={{
        maxHeight: 400
      }}
      // layout="fullWidth"
      // aspectRatio={3 / 1}
      alt=""
      objectPosition="center 30%"
      src="../images/homepage-header.jpg"
      formats={["auto", "webp", "avif"]}S
    />

    <div className="container mx-auto mt-4 md:mt-16">
      <div className="md:flex p-4 md:p-0">
        <div className="md:w-1/3 mb-4 md:mb-0">
          <h1 className="text-3xl font-bold text-brand-purple">About Shift Power for Peace</h1>
        </div>
        <div className="md:border-l-2 md:border-brand-purple md:pl-8 md:w-2/3">
          <p className="text-xl text-brand-purple"><span className="text-brand-orange">Shift Power for Peace (SP4P)</span> is a collaborative initiative founded on the belief that the current global peacebuilding model, where power and decision making is held by people furthest away from conflict, is not working, and that the key to sustainable peace is local leadership. Working with champions in all parts of the peacebuilding ecosystem, we seek to shift power and agency to local change agents building peace in their own communities.</p>
          <p className="mt-4 text-xl text-brand-purple">SP4P is founded by Conducive Space for Peace, Humanity Unity and Peace Direct.</p>
          <div className="md:flex mt-8">
            <div>
              <a href="https://www.conducivespace.org/">
                <StaticImage
                  height={50}
                  src="../images/csp-logo.png"
                  alt="Logo of Conducive Space for Peace"
                />
              </a>
            </div>
            <div className="my-8 md:my-0 md:mx-16">
              <a href="https://humanityunited.org/">
                <StaticImage
                  height={50}
                  src="../images/humanity-united-logo.jpg"
                  alt="Logo of Humanity United"
                />
              </a>
            </div>
            <div>
              <a href="https://www.peacedirect.org/">
                <StaticImage
                  height={50}
                  src="../images/peace-direct-logo.jpg"
                  alt="Logo of Peace Direct"
                />
              </a>
            </div>
          </div>
        </div>
      </div>      
    </div>
  </Layout>
)

export default IndexPage
